<template>
  <div class="wizard-container">
    <div class="progress-bar-container" v-if="savingSurveyInProgress">
      <label v-html="savingProgressContent"></label><br/>
      <progress-bar
        :options="ProgressBarOptions"
        :value="progressBarValue"
      />
    </div>
    <form data-vv-scope="add-survey" v-if="$store.state.userData.role =='super-admin' || $store.state.settings.enable_survey_feature == 1">
      <form-wizard
        @on-complete="surveySaved"
        @on-error="handleErrorMessage"
        title="Create Survey"
        subtitle=""
        shape="tab"
        back-button-text="Back!"
        next-button-text="Next!"
        finish-button-text="Submit"
        color="#82DE6D"
        v-if="$store.state.userData.role =='super-admin' || $store.state.userData.surveyFeature == 1"
      >
          <!-- basic survey details tab -->
          <tab-content title="Basic Details" icon="ti-align-justify" :before-change="checkBasicDetailsValidation" >
            <BasicSurveyDetails :basicSurveyDetails="basicSurveyDetails" @basicDetailsUpdated="updateSurveyBasicDetails" :surveyId="surveyId"/>
          </tab-content>
          <!-- questions and answers details tab -->
          <tab-content title="Add Quesions" icon="ti-settings" :before-change="checkQuestionAnswersValidateion">
            <span class="localstorage-existing-data-notice" v-if="QuestionDataExistsInLocalStorage">You have unsaved changes in your brwoser, click <a href="javascript:;" @click="fillLocalStorageQuestioncDetails">here</a> to prefill those!</span>
            <div class="row single-quesion-box" v-for="(question, qi) in surveyQuestionAnswers" :key="qi">
              <h3 style="text-align:center;color:#782B55;margin-bottom:15px;font-weight:bold;">Question {{qi + 1}}</h3>
              <div class="col-xs-12 col-sm-6">
                <input
                  type="text"
                  :placeholder="'Enter Question ' + (qi + 1) + ' Text'"
                  :name="'surveyQuestion_' + qi"
                  v-model="question.question"
                  v-validate="'required'"
                  :class="{'input': true, 'is-danger': errors.has('add-survey.surveyQuestion_' + qi ) }"
                >
              </div>
              <div class="col-xs-12 col-sm-6">
                <select
                  :name="'surveyQuestionType_' + qi"
                  v-model="question.questionType"
                  v-validate="'required'"
                  :class="{'input': true, 'is-danger': errors.has('add-survey.surveyQuestionType_' + qi) }"
                >
                <option value="">Select Answer Type</option>
                  <option value="SINGLE_ANSWER" v-if="basicSurveyDetails.type !=='NON_GEO'">Single Select</option>
                  <option value="MULTIPLE_ANSWER" v-if="basicSurveyDetails.type !=='NON_GEO'">Multi Select</option>
                  <option value="TEXT">Textual Value</option>
                </select>
              </div>
              <div v-if="question.questionType != 'TEXT' && basicSurveyDetails.type !='NON_GEO'">
                <div class="row answer-container" v-for="(answer, ai) in question.answers" :key="ai">
                  <div class="col-xs-6">
                    <input
                      type="text"
                      :placeholder="'Answer '+ (ai+1) "
                      :name="'surveyAnswers_' + qi + '_' +ai "
                      v-model="answer.answerOption"
                      class="answer-input"
                      v-validate="'required'"
                      :class="{'input': true, 'is-danger': errors.has('add-survey.surveyAnswers_' + qi + '_'  + ai) }"
                    >
                    <a href="javascript:;" v-if="ai > 1"  @click="removeAnswer(qi, ai)" class="pull-right remove-answer-button">x</a>
                  </div>
                </div>
              </div>
              <a class="add-more-ans" v-if="question.questionType !='TEXT' && basicSurveyDetails.type == 'PICKL' && question.answers.length < $store.state.settings.max_answers_per_survey_question" href="javascript:;" @click="addAnswerToQuestion(qi)">Add Another Answer</a>
              <a class="add-more-ans" v-if="question.questionType !='TEXT' && basicSurveyDetails.type == 'MEDIA' && question.answers.length < $store.state.settings.max_answers_per_media_survey_question" href="javascript:;" @click="addAnswerToQuestion(qi)">Add Another Answer</a>

              <a href="javascript:;" @click="removeQuestion(qi, question.id)" class="pull-right delete-question-link" v-if="qi > 0">Delete Question</a>
            </div>

            <a href="javascript:;" class="add-question-button" @click="AddQuestion" v-if="basicSurveyDetails.type == 'MEDIA' && surveyQuestionAnswers.length < this.$store.state.settings.max_questions_per_media_survey">+  Question</a>
            <a href="javascript:;" class="add-question-button" @click="AddQuestion" v-if="basicSurveyDetails.type == 'PICKL' && surveyQuestionAnswers.length < this.$store.state.settings.max_questions_per_survey">+  Question</a>
            <a href="javascript:;" class="add-question-button" @click="AddQuestion" v-if="basicSurveyDetails.type == 'NON_GEO' && surveyQuestionAnswers.length < this.$store.state.settings.max_questions_per_non_geo_survey">+  Question</a>
          </tab-content>

          <!-- reivew survey tab -->
          <tab-content title="Review" icon="ti-eye" :before-change="submitForm">
            <ReviewSurvey :basicSurveyDetails="basicSurveyDetails" :surveyQuestionAnswers="surveyQuestionAnswers" />
          </tab-content>
      </form-wizard>
      <div v-else>
          <h2 style="text-align:center;">This feature is only available for <strong>Essentials</strong> & <strong>Pro</strong> plans<br/>click <router-link to="/brand/subscription"> <strong class="upgrade-link">Here</strong></router-link> to upgrade your plan</h2>
      </div>
    </form>
    <h2 v-else style="text-align:center;">Survey Feature is not available for you!</h2>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import BasicSurveyDetails from '@/components/common/Survey/BasicDetails'
import ReviewSurvey from '@/components/common/Survey/ReviewSurvey'
import { stringify } from '@amcharts/amcharts4/.internal/core/utils/Utils'
import ProgressBar from 'vuejs-progress-bar'

export default {
  data () {
    return {
      surveyId: this.$route.params.surveyId,
      SurveyDetailsDuringSaving: [],
      savingProgressContent: '',
      savingSurveyInProgress: false,
      progressBarValue: 0,
      ProgressBarOptions: {
        text: {
          // hideText: true
        },
        progress: {
          color: '#43DB6D',
          backgroundColor: '#782B55'
        },
        layout: {
          width: '500',
          height: '30'
        }
      },
      brandId: 0,
      QuestionDataExistsInLocalStorage: false,
      basicSurveyDetails: {
        title: '',
        description: '',
        type: ''
      },
      surveyQuestionAnswers: []
    }
  },
  name: 'CreateSurvey',
  components: {
    FormWizard,
    TabContent,
    BasicSurveyDetails,
    ProgressBar,
    ReviewSurvey
  },
  methods: {
    surveySaved () {
    },

    async submitForm () {
      window.scrollTo(0, 0)
      await this.saveSurvey()
      return true
    },
    // Save or update the survey
    async saveSurvey () {
      this.basicSurveyDetails = JSON.parse(localStorage.getItem('SurveyBasicDetails'))
      let action = 'saveSurvey'
      this.savingSurveyInProgress = true
      let survey = {
        id: this.basicSurveyDetails.id,
        brandId: this.brandId,
        description: this.basicSurveyDetails.description,
        type: this.basicSurveyDetails.type,
        title: this.basicSurveyDetails.title,
        userId: this.$store.state.userData.id,
        status: 'ACTIVE'
      }
      let context = 'Saving'
      if (typeof survey.id !== 'undefined') {
        action = 'updateSurvey'
        context = 'Updating'
      }
      this.savingProgressContent = context + ' Survey...'
      let response = await this.$store.dispatch(action, survey).then((response) => { return response })
      let questions = JSON.parse(localStorage.getItem('SurveyQuestionDetails'))
      let totalSteps = questions.length + 1
      this.progressBarValue = Math.round((1 / totalSteps) * 100)
      if (response.status === 200 || response.status === 204) {
        if (response.status === 200) {
          this.basicSurveyDetails.id = response.body.id
          survey.id = response.body.id
        }
        localStorage.setItem('SurveyBasicDetails', stringify(this.basicSurveyDetails))
        let i = 1
        let quesionIndex = 0
        for (const question of questions) {
          let context = 'Saving'
          if (typeof question.id !== 'undefined') {
            context = 'Updaing'
          }
          this.savingProgressContent = context + ' Question ' + i + ' of ' + questions.length
          await this.saveQuestion(question, survey, quesionIndex)
          i++
          quesionIndex++
          this.progressBarValue = Math.round((i / totalSteps) * 100)
        }
        this.savingProgressContent = 'Survey Saving Completed'
        localStorage.removeItem('SurveyQuestionDetails')
        localStorage.removeItem('SurveyBasicDetails')
        if (this.$store.state.userData.role === 'brand') {
          this.$router.push('/brand/insights')
        }
        if (this.$store.state.userData.role === 'super-admin') {
          this.$router.push('/sadmin/insights/' + this.brandId)
        }
        this.$notify({ type: 'success', text: 'Insight has been updated successfully!' })
      }
    },

    // save or update the question
    async saveQuestion (question, survey, questionIndex) {
      let action = 'saveQuestion'
      let data = {
        id: question.id,
        question: question.question,
        questionType: question.questionType,
        status: 'ACTIVE',
        surveyId: survey.id,
        userId: this.$store.state.userData.id,
        brandId: this.brandId
      }
      if (typeof data.id !== 'undefined') {
        action = 'updateQuestion'
      }
      let response = await this.$store.dispatch(action, data).then((response) => { return response })
      if (response.status === 200 || response.status === 204) {
        this.SurveyDetailsDuringSaving = JSON.parse(localStorage.getItem('SurveyQuestionDetails'))
        if (response.status === 200) {
          this.SurveyDetailsDuringSaving[questionIndex].id = response.body.id
          question.id = response.body.id
        }
        localStorage.setItem('SurveyQuestionDetails', stringify(this.SurveyDetailsDuringSaving))
        let answerIndex = 0
        if (question.questionType !== 'TEXT') {
          for (const answer of question.answers) {
            await this.saveAnswer(answer, question, survey, questionIndex, answerIndex)
            answerIndex++
          }
        }
      }
      return response
    },
    // Save or update the answer
    async saveAnswer (answer, question, survey, questionIndex, answerIndex) {
      let action = 'saveAnswer'
      let data = {
        id: answer.id,
        answerOption: answer.answerOption,
        questionId: question.id,
        status: 'ACTIVE',
        surveyId: survey.id,
        userId: this.$store.state.userData.id,
        brandId: this.brandId
      }
      if (typeof data.id !== 'undefined') {
        action = 'updateAnswer'
      }
      let response = await this.$store.dispatch(action, data).then((response) => { return response })
      if (response.status === 200 || response.status === 204) {
        this.SurveyDetailsDuringSaving = JSON.parse(localStorage.getItem('SurveyQuestionDetails'))
        if (response.status === 200) {
          this.SurveyDetailsDuringSaving[questionIndex].answers[answerIndex].id = response.body.id
        }
        localStorage.setItem('SurveyQuestionDetails', stringify(this.SurveyDetailsDuringSaving))
      }
      return response
    },

    checkBasicDetailsValidation () {
      if (this.basicSurveyDetails.title !== '' & this.basicSurveyDetails.description !== '' & this.basicSurveyDetails.type !== '') {
        localStorage.setItem('SurveyBasicDetails', stringify(this.basicSurveyDetails))
        return true
      } else {
        return false
      }
    },
    updateSurveyBasicDetails (basicDetails) {
      this.basicSurveyDetails = basicDetails
    },

    /** this method add the new question to survey questions template */
    AddQuestion () {
      this.surveyQuestionAnswers.push({
        question: '',
        questionType: '',
        answers: [{ answerOption: '' }, { answerOption: '' }]
      })
    },

    removeQuestion (index, questionId) {
      this.surveyQuestionAnswers.splice(index, 1)
      if (typeof questionId !== 'undefined') {
        this.$store.dispatch('deleteSurveyQuestion', { brandId: this.brandId, surveyId: this.surveyId, questionId: questionId })
      }
    },

    /** these methods add or remove answer from question */
    addAnswerToQuestion (questionIndex) {
      this.surveyQuestionAnswers[questionIndex].answers.push({ answerOption: '' })
    },
    removeAnswer (questionIndex, answerIndex) {
      console.log(this.surveyQuestionAnswers[questionIndex])
      this.surveyQuestionAnswers[questionIndex].answers.splice(answerIndex, 1)
    },

    /** check for validation of question and answers */
    checkQuestionAnswersValidateion () {
      return new Promise((resolve, reject) => {
        this.$validator.validateAll('add-survey').then((result) => {
          if (result) {
            localStorage.setItem('SurveyQuestionDetails', stringify(this.surveyQuestionAnswers))
            resolve(true)
          } else {
            this.$notify({ type: 'error', text: 'Please fill all fields before proceeding!' })
            reject(this.handleErrorMessage('error'))
          }
        })
      })
    },
    handleErrorMessage (errMsg) {

    },
    checkLocalStorageForQuestionDetails () {
      let localStorageQuestionDetails = localStorage.getItem('SurveyQuestionDetails')
      if (localStorageQuestionDetails !== null) {
        this.QuestionDataExistsInLocalStorage = true
      }
    },
    fillLocalStorageQuestioncDetails () {
      let data = JSON.parse(localStorage.getItem('SurveyQuestionDetails'))
      this.surveyQuestionAnswers = data
      this.QuestionDataExistsInLocalStorage = false
    },
    getSurveyById () {
      this.$store.dispatch('getSurveyById', { brandId: this.brandId, surveyId: this.surveyId }).then((response) => {
        this.basicSurveyDetails = { id: response.response.body.id, title: response.response.body.title, description: response.response.body.description, type: response.response.body.type }
        for (const question of response.response.body.survey_questions) {
          let answersArray = []
          for (const answer of question.survey_question_answers) {
            answersArray.push({ id: answer.id, answerOption: answer.answer_option })
          }
          this.surveyQuestionAnswers.push({
            id: question.id,
            question: question.question,
            questionType: question.question_type,
            answers: answersArray
          })
        }
      })
    },
    getQuestionsBySurveyId () {
      this.$store.dispatch('getQuestionsBySurveyId', { brandId: this.brandId, surveyId: this.surveyId }).then((questionResponse) => {
        for (const question of questionResponse.response.body) {
          this.$store.dispatch('getAnswersByQuestionId', { brandId: this.brandId, surveyId: this.surveyId, questionId: question.id }).then((answerResponse) => {
            let answersArray = []
            for (const answer of answerResponse.response.body) {
              answersArray.push({ id: answer.id, answerOption: answer.answerOption })
            }
            this.surveyQuestionAnswers.push({
              id: question.id,
              question: question.question,
              questionType: question.questionType,
              answers: answersArray
            })
          })
          this.surveyQuestionAnswers.sort(function (a, b) {
            return a.id - b.id
          })
        }
      })
    }
  },
  mounted () {
    this.surveyName = localStorage.surveyName
    this.surveyDescription = localStorage.surveyDescription
    this.checkLocalStorageForQuestionDetails()
    this.getSurveyById()
    // this.getQuestionsBySurveyId()
    // this.getSurveyWithQuestionAnswersById()
  },
  created () {
    console.log(this.$store.state.userData.role)
    if (this.$store.state.userData.role === 'super-admin') {
      this.brandId = this.$route.params.brandId
    } else {
      console.log(this.$store.state.userData.brandId)
      this.brandId = this.$store.state.userData.brandId
    }
    this.$store.dispatch('getAppSettingsKeyValuePair')
  }
}
</script>
<style scoped>
.wizard-container {
  width:70%;
  margin:auto;
  border: solid 2px rgb(130, 222, 109);
  padding:1%;
  border-radius:20px;
}
select,
select:hover {
  border: 1px solid #6f3053;
  border-radius: 3px;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 20px;
  color: black;
  width: 100%;
  padding: 14px 42px 14px 16px;
  height: 50px;
  background-position: right 11px center;
  background-size: 20px 12px;
  background-image: url("/img/Create Pickl/selectproductDropdown_icon.png");
  background-color: white;
}
.answer-input {
  height: 30px;
  border-radius: 10px;
  margin:0;
  width:90%;
}
.add-more-ans {
  font-size: 15px;
  text-decoration: none;
  display: block;
  margin-left: 3%;
}
.single-quesion-box {
  border: solid 1px rgb(130, 222, 109);
  padding:1%;
  border-radius:20px;
  margin: 10px;

}
.add-question-button {

  border: solid 1px rgb(130, 222, 109);
  color: black;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  display: block;
  width: 20%;
  text-align: center;
  padding: 1%;
  border-radius: 10px;
}
a {
  color:#782b54;
}
.delete-question-link {
  font-size:16px;
  margin-top:40px;
}
.answer-container {
  margin-left: 2%;
  margin:1%;
}
.remove-answer-button {
  background: #782b54;
  height: 35px;
  border: none;
  border-radius: 150px;
  color: white;
  padding: 3px 10px 0px 10px;
}
.localstorage-existing-data-notice {
    text-align: center;
    display: block;
    color: black;
    font-size: 16px;
    margin-bottom: 10px;
    transition: opacity cubic-bezier(0, 0.52, 1, 1) 1s
  }
  .progress-bar-container {
    width: 60%;
    margin: auto;
  }
@media only screen and (max-width: 1024px) {
  .wizard-container {
    width:100%;
    margin:auto;
    border: solid 2px rgb(130, 222, 109);
    padding:1%;
    border-radius:20px;
  }

}
</style>
